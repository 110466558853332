import React from 'react'
import "./gallery.css"
import img1 from "../../../Assets/p1.png"
import img2 from "../../../Assets/p2.png"
import img3 from "../../../Assets/p3.jpeg"
const Gallery = () => {
    const images = [
        {url:"https://www.iiitbh.ac.in/sites/default/files/inline-images/PKJ_Desk.jpg"},
        {url: img1},
        {url:"https://alumni.iiitbh.ac.in/int.png"},
        {url:img2},
        {url:img3},
        {url: 'https://alumni.iiitbh.ac.in/award.jpg'}
      
    ]
  return (
    <div className="headcontainer">
        <h1>Gallery</h1>
          <div className='galleryMain'>

{images.map((images,index) =>(
  <div className="galleryimage" key={index}>
      <img src={images.url} alt="" />

  </div>
))}
</div>
    </div>
  
  )
}

export default Gallery
