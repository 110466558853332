import React from "react";
import "./others.css";
import img1 from "../../../Assets/Rectangle 8.png";
import img2 from "../../../Assets/1.png";
const others = [
  {
    name: "Tutorials Point",
    src: img1,
  },
  {
    name: "Exchange Oppurtunities",
    src: img2,
  },
  {
    name: "Post Jobs/Internships",
    src: img1,
  },
  {
    name: "Share Memories",
    src: img2,
  },
  {
    name: "Network",
    src: img1,
  },
 
];
const Others = () => {
  return (
 
    <div className="othersMain">
      {others.map((othersDetail, index) => (
        <div key={index} className="other">
          <img src={othersDetail.src} alt="" />
          <h5>{othersDetail.name}</h5>
        </div>
      ))}
    </div>
  );
};

export default Others;
