import React from "react";
import Midphoto from "./Midphoto/Midphoto";
import Event from "./Events/Event";
import Topdoner from "./TopDoner/Topdoner";
import "./homepage.css";
import Newsroom from "./Newsroom/Newsroom";
import Others from "./Others/Others";
import Description from "./Description/Description";
import Gallery from "./Gallery/Gallery";
import img1 from "../../Assets/p1.png"
import img2 from "../../Assets/p2.png"
import img3 from "../../Assets/p3.jpeg"
const Homepage = () => {
  const slides = [
    { url: "https://alumni.iiitbh.ac.in/int.png", caption: "one" },
    { url: img1, caption: "two" },
    { url: img2, caption: "three" },
    {
      url: img3,
      caption: "four",
    },
  ];

  const containerStyles = {
    width: "95%",
    height: "700px",
    margin: "10px auto",
  };

  return (
    <div className="mainHome">
      <div style={containerStyles}>
        <Midphoto slides={slides} />
      </div>
      <div className="eventAndTopDonerComp">
        <Event />
        <Topdoner />
      </div>
      <div className="newsroomComp">
        <Newsroom />
      </div>
      <div className="othersComp">
        <Others />
      </div>
      <div className="descriptionComp">
        <Description />
      </div>
      <div className="galleryComp">
        <Gallery />
      </div>
    </div>
  );
};

export default Homepage;
