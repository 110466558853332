import React from "react";
import "./register.css";

const RegisterComponent = () => {
  return (
    <>
      <h1>Register</h1>
      <div className="box">
        <form>
          <br />
          <br />
          <label>
            Username <br />
          </label>
          <br />
          <input type="text" />
          <br />
          <br />
          <label>
            Password <br />
          </label>
          <br />
          <input type="password" />
          <br />
          <br />

          <label>
            Confirm Password <br />
          </label>
          <br />
          <input type="text" />
          <br />
          <br />
          <label>Year of Passing</label>
          <br />
          <br />
          <input list="year of passing" name="year of passing" />
          <datalist id="year of passing">
            <option value="2023" />
            <option value="2022" />
            <option value="2021" />
          </datalist>
          <br />
          <br />
          <label>Your Department</label>
          <br />
          <br />
          <input list="department" name="department" />
          <datalist id="department">
            <option value="CSE" />
            <option value="ECE" />
            <option value="MEA" />
          </datalist>
          <br />
          <br />
          <label>Upload Your Passport Size Photo</label>
          <br />
          <br />
          <input type="file" />
          <br />
          <br />
          <br />
          <div class="button">
            <button onclick="alert">Register</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegisterComponent;
