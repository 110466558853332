import React from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import About from "../src/Components/AboutUs/AboutUs";
import { Routes, Route } from "react-router-dom"
import Homepage from "./Components/HomePage/Homepage";
import Membership from "./Pages/Membership";
import EventsComponent from "./Components/Events/events";
import Footer from "./Components/Footer/Footer"
import Newsroom from "./Pages/Newsroom";
import AlumniSearch from "./Pages/AlumniSearch";
import Login from "./Components/Login/login"
import Register from "./Components/Register/register"
function App() {
  return (
   <>
      <Header/>
      <Routes>
        <Route path="/" element={ <Homepage/> } />
        <Route path="/about" element={ <About/> } />
        <Route path="/newsroom" element={ <Newsroom/> } />

        <Route path="/membership" element={ <Membership/> } />
        <Route path="/events" element={ <EventsComponent/> } />
        <Route path="/alumniSearch" element={ <AlumniSearch/> } />
        <Route path="/login" element={ <Login/>}/>
        <Route path="/register" element={ <Register/>}/>

      </Routes>
      <Footer/>
      </>
    
  )
}

export default App
