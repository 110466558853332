import React from "react";
import "./newsroom.css";
// import { Link } from "react-router-dom";
const Newsroom = () => {
  // const news = [
  //   { Year: "2023", month: ["January", "February", "March", "December"] },
  //   { Year: "2022", month: ["January", "February", "March", "December"] },
  //   { Year: "2021", month: ["January", "February", "March", "December"] },
  //   { Year: "2020", month: ["January", "February", "March", "December"] },
  //   { Year: "2019", month: ["January", "February", "March", "December"] },
  // ];
  return (
    <div className="newsroomDiv">
      {/* <h1>Newsroom</h1>
      <h6>
        All Past Articles can be accessed by clicking the appropiate links for
        an year.
      </h6>
      <div className="newslist">
        {news.map((item, index) => (
          <div className="newsItem" key={index}>
            <h4>{item.Year}</h4>
            <ul>
              {item.month.map((element, i) => (
                <div className="month" key={i}>
                  <li> <Link className="Link">{element}</Link></li>
                </div>
              ))}
            </ul>
          </div>
        ))}
      </div> */}
      <div class="wrapper">
  <h1>coming soon<span class="dot">.</span></h1>
  
 </div>
    </div>
  );
};

export default Newsroom;
