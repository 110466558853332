import React from "react";
import "./header.css";
import Navbar from "../Navbar/Navbar";
import Logo from "../../Assets/logo.png";
import CLogo from "../../Assets/Ellipse2.png";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <>
      <div className="mainHeader">
        <div className="header">
          <div className="hleft">
          <Link to="/" className='linkNav'> <img src={Logo} alt="" /></Link>
          <div className="vr"></div>
          <Link to="/" className='linkNav'> <img src={CLogo} alt="" /></Link>

            <div className="title">
              <h1>Indian Institute of Information Technology, Bhagalpur</h1>
              <h5>Alumni Association</h5>
            </div>
          </div>

          <div className="headerBtns">
            <button > <Link className="Link" to="register">Register</Link> </button>
            <button> <Link className="Link" to ="login">Login</Link> </button>
          </div>
        </div>
        <div className="nav">
          <Navbar />
        </div>
      </div>
    </>
  );
};

export default Header;
