import React from "react";
import "./events.css";
import EventDB from "./EventDB";
const EventsComponent = () => {

  return (
    <div className="eventsComp">
      <h1>Events</h1>
      <div className="tableIn">
        <h4>Events Calender ‘23</h4>
        <table>
          <thead>
            <tr>
              <th>Events</th>
              <th>Date</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {EventDB.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.event}</td>
                  <td className="eventDate">{item.date}</td>
                  <td>
                    {" "}
                    <button>{item.type}</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventsComponent;
