import React from "react";
import "./login.css";

const LoginComponent = () => {
  
  return (
   <>
    <h1>Login</h1>
    <div class="box" >
      
        <p>
        <centre><form >
            <br/><br/>
    <label>Username <br/></label><br/>
      <input type="text" /><br/><br/>
    <label>Password <br/></label><br/>
      <input type="password"  />
        <br/><br/><br/>
        <div class="button">
    <button onclick="alert">Login</button></div>
    
  </form></centre>
  </p>
  <p class="register"><b>Alumni but not registered?<a href="link here">Register here</a></b></p><br/>
  </div>
   </>
      
    
  );
};

export default LoginComponent;