 const events = [
    { event: "Enyugma 2022", date: "9th – 11th Dec `22", type: "Join" },
    { event: "Udyatva 2023", date: "22nd – 24th Feb `23", type: "Join" },
    { event: "Har Ghar Tiranga", date: "14th August `23", type: "Join" },
    {
      event: "Technical Orientation",
      date: "9th – 10th Sep `23",
      type: "Join",
    },
    { event: "Cultural Orientation", date: "17th Sep `23", type: "Join" },
    { event: "Enyugma 2023", date: "24th – 26th Nov `23", type: "Join" },
    { event: "Online Alumni Meet", date: "To be updated soon", type: "Join" },
  ];
  export default events