import React from 'react'
import "./description.css"
const Description = () => {
    const describtion = [
        {name:"Prof. Pradip Kumar. Jain, Director, IIIT Bhagalpur",
        caption:"Indian Institute Of Information Technology Bhagalpur is progressing along an innovative route. As you are aware, it is already having an influence on the nation as a technical institute, but that is only the beginning of the institute's massive initiatives and advancements. We have made an incredible start, but there is still a long way to go. But, I am confident that with the enthusiastic support of all of our alumni, we will be able to live up to the motto of IIIT Bhagalpur.",imageUrl:"https://www.iiitbh.ac.in/sites/default/files/inline-images/PKJ_Desk.jpg"
       ,
    },
        {name:"Dr. Prakash Ranjan (Associate Dean, Alumini Association)",
        caption:"The impressive alumni of our institution continue to inspire interest in our students with their numerous accomplishments and memorable experiences. Our college's alumni truly form its foundation in terms of talent, creativity, compassion, and greatness. I appreciate your support, and I thank each of you.",   imageUrl:"https://iiitbh.ac.in/sites/default/files/inline-images/prakashrajan.jpg",
    }
        
       
    ];
  return (
    <div className='descriptionMain'>
     {describtion.map((details,index) =>(
        <div key={index} className="details" >
            <div className="img">
                <img src={details.imageUrl} alt="" />
            </div>
            <div className="description">
                <span className='descName'>{details.name}</span>
                <span className='descCaption'>{details.caption}</span>
            </div>
        </div>
     ))}
    </div>
  )
}

export default Description
