import React from "react";
import "./topdoner.css";
const donersList = [
  { name: "Subarnajit Saha", amount: "Further Studies" },
  { name: "Ayush Kumar Bhardawaj", amount: "Present" },
  { name: "Aniket Kishor ", amount: "Reliance jio" },
  { name: "Shivam Kumar Vimal", amount: "Reliance jio" },
  { name: "Aditya Anand", amount: "Synopsys" },
  { name: "Sohail Khan", amount: "Synopsys" },
  { name: "Arunit Kumar", amount: "Synopsys" },
  { name: "Siddharth Kumar", amount: " Synopsys " },
  { name: "Sourabh prasad", amount: "Nxtwave " },
];
const Topdoner = () => {
  return (
    
      <div className="doners">
        <div className="scroll1">
        <h2>Top Alumni
</h2>

        <div className="divScroll">
          <div className="scroll">
            {donersList.map((donerDetail, index) => (
              <div key={index}>
                <span>
                  
                  <h4>{donerDetail.name}</h4>
                  <p>{donerDetail.amount}</p>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topdoner;
