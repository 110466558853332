import React, { useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const [nav, setnav] = useState("navMobil");
  const translateNav = () => {
    setnav("navMobil setnavMobile");
  };
  const backNav = () => {
    setnav("navMobil ");
  };
  return (
    <div className="navbar">
      <div className={nav}>
        <div className="closeIcon" onClick={backNav}>
          <CloseIcon />
        </div>

        <ul>
          <li onClick={backNav}>
            {" "}
            <Link to="/about" className="linkNav">
              About Us
            </Link>
          </li>
          <li onClick={backNav}>
            <Link to="/membership" className="linkNav">
              Membership
            </Link>
          </li>
          <li onClick={backNav}>
            <Link to="/newsroom" className="linkNav">
              Newsroom
            </Link>
          </li>
          <li onClick={backNav}>
            <Link to="/events" className="linkNav">
              Events
            </Link>
          </li>
          <li onClick={backNav}>
            <a href="/alumni-search" className="linkNav">
              Alumni Search
            </a>
          </li>
        </ul>
      </div>

      <button> <Link className="Link" to="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=3661358" >Donate Now</Link></button>
      <div className="menuIcon" onClick={translateNav}>
        <MenuIcon className="menu" />
      </div>
    </div>
  );
};

export default Navbar;
