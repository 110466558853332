import React from "react";
import "./Membership.css";
import { Link } from "react-router-dom";
const Membership = () => {
  return (
    <div className="memberships">
      <h1 class="heading">Membership</h1>
      <div class="questions">
        <div class="group">
          <h1 class="groupHeading ">Why Membership ?</h1>
          <span class="span">
            <b>
              Joining an alumni association can offer various benefits and
              opportunities, and here are some reasons why you might want to
              consider becoming a member:
            </b>
            <br />
            <ol>
              <br />
              <li>
                Networking: Alumni associations provide a platform for
                networking with fellow graduates, which can be valuable for
                personal and professional growth. You can connect with people
                from various backgrounds and industries, potentially leading to
                job opportunities, mentorship, or partnerships.{" "}
              </li>
              <br />
              <li>
                Stay Connected: Alumni associations help you stay connected to
                your alma mater, keeping you updated on the latest developments,
                events, and achievements at the college.{" "}
              </li>
              <br />
              <li>
                Nostalgia: What better memories can you possibly have than your
                college and the life of that 4 years. Sometimes reliving those
                memories over an alumni meet is all one needs.{" "}
              </li>{" "}
              <br />
              <li>
                Career Advancement: Many alumni associations offer career
                services, job boards, and career development resources to help
                you with your professional growth. You might get access to job
                listings, resume workshops, and career counselling.{" "}
              </li>{" "}
              <br />
              <li>
                Reunions and Events: Alumni associations often organize
                reunions, social events, and professional development workshops.
                These events provide opportunities for reconnecting with old
                friends and making new ones.{" "}
              </li>{" "}
              <br />
              <li>
                Volunteering and Giving Back: Alumni associations typically
                encourage members to get involved in volunteering, fundraising,
                and giving back to the college. You can contribute your time,
                expertise, or financial support to help current students or
                support college initiatives.{" "}
              </li>{" "}
              <br />
              <li>
                Continuing Education: Some alumni associations offer
                opportunities for continuing education, such as workshops,
                lectures, or access to online courses. This can help you further
                your knowledge and skills.{" "}
              </li>{" "}
              <br />
              <li>
                Access to Resources: Depending on the association and the
                college, you may get access to the college's resources,
                including libraries, research materials, and facilities.{" "}
              </li>{" "}
              <br />
              <li>
                Social and Cultural Activities: Alumni associations often host
                social and cultural events, which can be a great way to stay
                engaged in your community and explore new interests.{" "}
              </li>{" "}
              <br />
              <li>
                Alumni Directories: Many alumni associations maintain
                directories of members, which can be useful for reconnecting
                with old friends, reaching out for professional purposes, or
                expanding your social and professional circles.{" "}
              </li>{" "}
              <br />
              <li>
                Pride and Tradition: Being part of an alumni association allows
                you to carry on the tradition and legacy of your college. It's a
                way to show pride in your alma mater and celebrate its
                achievements.{" "}
              </li>
            </ol>
          </span>
        </div>

        <div class="group">
          <h1 class="groupHeading">Can I become member ?</h1>
          <p class="">
            If you are a part of IIIT Bhagalpur fraternity and if you have
            completed your graduation, postgraduation or your Doctorate from
            IIITBH then yes you can become a member of IIITBH-AA.
          </p>
        </div>

        <div class="group">
          <h1 class="groupHeading">How to become member</h1>
          <br />
          <h3>Members will be of 3 categories: </h3>
          <br />
          <ol>
            <br />
            <li>
              <b>Regular Members </b> <br /> The students of all Undergraduate,
              Postgraduate and Doctoral courses who graduate from the Indian
              Institute of Information Technology, Bhagalpur after successfully
              completing the courses can become Members of the Association on
              payment of a prescribed Membership fee. This shall also include
              students of all future programs as and when instituted by the
              institution e.g., BBA, MBA, BSc, MSc etc. Please note that this
              Membership status automatically stands withdrawn in case of
              failure to renew Membership for TWO (02) CONSECUTIVE YEARS.
            </li>
            <br />
            <li>
        
              <b>Honorary Members </b> <br /> The Association or a Chapter can
              offer "Honorary Membership" to a person of eminence (senior
              executive of Government / PSU / MNC/Academics/ a reputed Indian
              company or a person of National or International repute). For
              this, prior approval of the Governing Body shall be obtained. They
              shall be ineligible for voting rights and posts of Governing Body.
            </li>
            <br />
            <li>
              <b>Life Members</b> <br />
              All Alumni of IIIT Bhagalpur has the option of choosing for a
              one-time Life Membership fee (fixed amount subject to revision
              periodically by Governing Body, after adjusting for inflation).
              This Membership fee is paid towards obtaining the Life Membership
              of IIITBHAA.{" "}
            </li>
            <br />
          </ol>
          <p class="">
            Not a member yet? Become a member <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfCn_nomTpwxDBWPMpUU1Fa9iPqQ1yUuwnjAI_qCKDa_F0V5A/viewform">Click here</Link>
          </p>
          <br />
          <p>
            <b>Note:</b> Any of the above Memberships stand to be cancelled in
            case any action of a member is found against the interests of the
            Alumni or Alumni Association, in general. The Governing Body at its
            discretion, OR on advice of Advisory Committee, is authorized to
            implement/ review such disciplinary actions.{" "}
          </p>
        </div>

       
      </div>
    </div>
  );
};
export default Membership;
