import React, {useEffect,useRef} from "react";
import EventDB from "../../Events/EventDB";
import "./event.css";

  

const Event = () => {
  const marqueeRef = useRef(null);

  useEffect(() => {
    const marqueeElement = marqueeRef.current
    // const  handleAnimationIteration = () => {
    //   setResetAnimation(true);
    // };

    const handleAnimationIteration = () => {
      marqueeElement.style.animation = 'none';
      setTimeout(() => {
        marqueeElement.style.animation = 'marquee 10s linear infinite';
      }, 10);
    };

    marqueeElement.addEventListener('animationiteration', handleAnimationIteration);

    return () => {
      marqueeElement.removeEventListener('animationiteration', handleAnimationIteration);
    };
  }, []);

  // useEffect(() => {
  //   if (resetAnimation) {
  //     marqueeRef.current.style.animation = 'none';
  //     setTimeout(() => {
  //       marqueeRef.current.style.animation = 'marquee 20s linear infinite';
  //       setResetAnimation(false);
  //     }, 10);
  //   }
  // }, [resetAnimation]);

  
  return (
    <div className="events">
      <h2>Events</h2>

      <div className="divScroll">
        <div className="scroll" ref={marqueeRef}>
          {EventDB.map((eventDetail, index) => (
            <div key={index}>
              <span>
                {" "}
                <h4 className="eventh4">{eventDetail.event}</h4>
                <p title="Event date">{eventDetail.date}</p>
              </span>
              <span className="eventCaption">{eventDetail.caption}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Event;
