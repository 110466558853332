import React from 'react';
import './AboutUs.css'; 
// import Img from "../../Assets/1.png"
import { Link } from 'react-router-dom';

const PageComponent = () => {
  return (
    <div className='aboutus'>
      <h1 className="Abtheading">About Us</h1>
      <div className="section">
        <span>
        <h1 className="Abtoverview">Objective</h1>
        <br />
      <h4>Founded in 2023, the Objectives of IIIT-BHAA (Indian Institute of Information Technology Bhagalpur Alumni Association) shall be:
</h4>
<br />
     <ol>
      <li>To bring the students of all Undergraduate, Postgraduate and Doctoral courses including MCA and MSc Courses who graduate from the Indian Institute of Information Technology, Bhagalpur, under one forum for exchange of experience, dissemination of knowledge and talents amongst its members and for the furtherance of fellowship, advancement of technical knowledge in general of the community and country.</li>
      <br />
      <li>To operate exclusively for educational support, charitable concerns and scientific research of the Members and students undergoing graduation or post-graduation.</li>
      <br />
      <li>To conduct seminars, conferences, workshops and meetings of professionals and faculties for the purpose of promotion of knowledge and skills in the respective field.</li>
      <br />
      <li>To create and establish endowments for granting scholarships and prizes to the students at <b>INDIAN INSTITUTE OF INFORMATION TECHNOLOGY BHAGALPUR</b> with a view to promote and encourage talents of all students at constituent colleges.</li>
      <br />
      <li>To aid in the recruitment, graduation and placement of students of the Institute.</li>
      <br />
      <li>To keep the Alumni updated regarding the latest activities in the Institute and encourage them to take keen interest in the progress of the Institute and in development of the Alma Mater.</li>
      <br />
      <li>To provide financial support to the students only based on scholarship acts, in consultation with the Institute.</li>
      <br />
      <li>To communicate the achievements of the Institute to the Members of the Association.</li>
      <br />
      <li>To publish magazines and newsletters highlighting the activities and achievements of the Association.</li>
      <br />
     </ol>
        </span>
      {/* <img className="Abtimg1" src={Img} alt=""/> */}
      </div>
      <div className="section ">
        <span>
        <h1 className="Abtoverview">Mission & Vision</h1>
      <br />
      <ol>
        <li>To improve Infrastructure, extra-curricular activities of the Institute and help needy and meritorious students.</li>
        <br />
        <li>To convene meetings and conventions occasionally for the benefit of Alma mater and Students of the Institute</li>
        <br />
        <li> To aid in the recruitment, graduation and placement of students at the Institute.</li>
        <br />
        <li>To communicate the achievements of the Alumni to other members of the Association and the Institute</li>
        <br />
        <li>Do all such acts and deeds as is considered necessary and helpful, to carry out and achieve the above objects of the society.</li>
        <br />
      </ol>
        </span>
     
      </div>
      <div className="section ">
        <span>
        <h1 className="Abtoverview">Governing Council (Executive Committee )</h1>
      <h4>Role and responsibility of Committee:</h4>
      <br />
      <h5>The Executive Committee shall consist of:</h5>
      <br />
      <ul>
        <li>President</li>
        <li>Secretary</li>
        <li>Joint Secretary</li>
        <li>Treasurer</li>
        <li>Alumni Relation Manager</li>
        <li>Outreach In-Charge</li>
        <li>Cultural Head</li>
        <li>Technical Head</li>
        <li>Office Administrator</li>
        <li>TnP Representative</li>
        <li>Members..</li>
     
      </ul>
     
        </span>
     
      </div>
<div className="mentors">
  <h4>First Draft of ByLaws of IIIT Bhagalpur Alumni Association: <Link to="https://alumni.iiitbh.ac.in/alumni%20association%20constitution.pdf">Click Here</Link>

</h4>
  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
</div>
      
      {/* <div className="Abtphotos">
        <img className="Abtprofile" src={Img} alt="Avatar"  />

        <img className="Abtprofile" src={Img} alt="Avatar"  />

        <img className="Abtprofile" src={Img} alt="Avatar"  />

        <img className="Abtprofile" src={Img} alt="Avatar" />
      </div> */}


        <div className="Abtbutton">
          <button>
            <a href="#back">BACK TO TOP</a>
          </button>
        </div>
  
    </div>
  );
};

export default PageComponent;
