import React from "react";
import "./newsroom.css";
const Newsroom = () => {
  const eventsList = [
    {
      title:
        "Inaguration of Alumni Website will be held on 12th of October, 2022",
      caption: "Alumini Website is basically developed for connecting our Alumni with the current Students. This site is developed by our Website Developed Team under the guidance of Dr. Prakash Ranjan (Associate Dean, Alumni Association) Our Alumni can use this website to showcase their data and connect with the Institute.",
     
      date: "21-08-2023",
    },
   
  ];
  return (
    <div>
      <div className="newsroom">
        <h2>Newsroom</h2>

        <div className="divScroll">
          <div className="scroll">
            {eventsList.map((newsroomDetail, index) => (
              <div key={index}>
               
                <h4>{newsroomDetail.title}</h4>

                <span className="newsroomCaption">{newsroomDetail.caption}</span>
                <p title="Date">{newsroomDetail.date}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsroom;
