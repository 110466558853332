import React, { useState } from "react";
import Img1 from "../Assets/Rectangle 8.png";
import "./alumniSearch.css";
const AlumniSearch = () => {
  const alumniList = [
    {
      year: "Year of 2022",
      student: [
        { name: "Bimlesh Kumar", url: Img1,branch:"CSE" },
        { name: "Bimlesh Kumar", url: Img1,branch:"CSE" },
        { name: "Bimlesh Kumar", url: Img1,branch:"CSE" },
        { name: "Bimlesh Kumar", url: Img1,branch:"CSE" },
        { name: "Bimlesh Kumar", url: Img1,branch:"CSE" },
        { name: "Bimlesh Kumar", url: Img1,branch:"CSE" },
        { name: "Bimlesh Kumar", url: Img1,branch:"CSE" },
    
      ],
    },
    {
      year: "Year of 2021",
      student: [
        { name: " Bimlesh kumar", url: Img1 },
        { name: "Student 1", url: Img1 },
        { name: "Student 1", url: Img1 },
        { name: "Student 1", url: Img1 },
      ],
    },
  ];

const [search ,setSearch] = useState("");


  return (
    <div className="alumniSearch">
      <h1>Search our Alumni</h1>

      <div className="alumniSearchAndList">
        <div className="search">
          <h4>Search by Name</h4>
          <input
            type="text"
            placeholder="Search for Alumni"
            onChange={(e) => setSearch(e.target.value)}
          />
          <button type="submit">Search</button>
        </div>
        <div className="list">
          {alumniList.map((item, index) => (
            <div className="yearList" key={index}>
              <h3>{item.year}</h3>
              <span>
                {item.student
                  .filter((item) => {
                    return search.toLowerCase() === ""
                      ? item
                      : item.name.toLowerCase().includes(search) || item.name.includes(search);
                  })
                  .map((e, i) => (
                    <div className="imgAndName" key={i}>
                      <img src={e.url} alt="" />
                      <h5>{e.name}</h5>
                      <h6>{e.branch}</h6>

                    </div>
                  ))}
              </span>
            </div>
          ))}


        </div>
      </div>
    </div>
  );
};

export default AlumniSearch;
