import React from "react";
import Logo from "../../Assets/Ellipse2.png"
import "./footer.css"
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="upperFooter">
        <img src={Logo} alt="" />
        <div className="upperFooterText">
          <h3>Indian Institute of Information Technology Bhagalpur</h3>
          <div className="contactLInks">
            <span>
            <LinkedInIcon/>
            </span>
        <span>
        <InstagramIcon/>
        </span>
        <span>
        <EmailIcon/>
        </span>
           
           
          </div>
          <p className="footerText">
            It is one of the IIITs set up by MHRD, Govt of India in
            PUBLIC-PRIVATE PARTNERSHIP(PPP) mode.  It started functioning from
            the academic year 2017
          </p>
        </div>
      </div>
      <div className="lowerFooter">
        <p> &copy; All Rights Reserved, 2023 IIIT Bhagalpur
           </p>
           <p>Designed and Developed with ❤ by WebVerse, IIIT Bhagalpur
</p>
      </div>
    </div>
  );
};

export default Footer;
